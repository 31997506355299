import React from 'react';
import { connect } from 'react-redux';
import {
  fetchPlayerGifts,
  setPlayerGifts,
  addPlayerGift,
  deletePlayerGift,
} from '../redux/playerActions';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Stripe from '../stripe.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import AddPlayerGift from './AddPlayerGift';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
  },
  dropzone: {
    textAlign: 'center',
    backgroundImage: `url(${Stripe})`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleOne: {
    textAlign: 'right',
  },
  titleTwo: {
    flexGrow: 1,
    textAlign: 'left',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

class PlayerGifts extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedGift: null, addGiftOpen: false };
  }

  handleBack = () => {
    this.props.setPlayerGifts(this.props.selected.user_id, null);
  };

  componentDidMount = () => {
    if (this.props.gameDesign == null) {
      this.props.fetchGameDesign();
    }
  };
  selectGift = (giftId) => {
    this.setState({ ...this.state, addGiftOpen: true, selectedGift: giftId });
  };
  handleAddGift = () => {
    this.setState({ ...this.state, addGiftOpen: true, selectedGift: null });
  };
  handleCloseAddGift = (gift) => {
    this.setState({ ...this.state, addGiftOpen: false, selectedGift: null });

    if (gift) {
      this.props.addPlayerGift(this.props.selected.user_id, gift);
    }
  };
  handleCloseDeleteGift = (gift) => {
    this.setState({ ...this.state, addGiftOpen: false, selectedGift: null });
    if (gift) {
      this.props.deletePlayerGift(this.props.selected.user_id, gift.reward_id);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <div>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton aria-label="back" color="inherit" onClick={this.handleBack}>
              <ArrowBackIosIcon />
            </IconButton>
            <Hidden mdDown>
              <Typography variant="h6">Gifts</Typography>
            </Hidden>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <List component="nav" aria-label="main mailbox folders">
            {this.props.selected.gifts.map((gift) => (
              <ListItem key={gift.reward_id} button onClick={() => this.selectGift(gift)}>
                <ListItemText primary={gift.admin_name} secondary={gift.reward_id} />
              </ListItem>
            ))}
            {this.props.selected.gifts.length === 0 && (
              <ListItem>
                <ListItemText primary="No gifts found" />
              </ListItem>
            )}
            <ListItem button onClick={this.handleAddGift}>
              <ListItemIcon>
                <CardGiftcardIcon />
              </ListItemIcon>
              <ListItemText primary="Add Gift" />
            </ListItem>
          </List>
        </div>
        <AddPlayerGift
          open={this.state.addGiftOpen}
          selectedGift={this.state.selectedGift}
          closeAddGift={this.handleCloseAddGift}
          closeDeleteGift={this.handleCloseDeleteGift}
          materials={this.props.gameDesign.material_collection.materials}
          briefs={this.props.gameDesign.briefs}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.player };
};

export default connect(mapStateToProps, {
  fetchPlayerGifts,
  setPlayerGifts,
  addPlayerGift,
  deletePlayerGift,
})(withStyles(styles)(PlayerGifts));
