import React from 'react';
import { connect } from 'react-redux';
import { fetchPlayerGifts, setPlayerGifts } from '../redux/playerActions';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Stripe from '../stripe.png';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InboxIcon from '@material-ui/icons/Inbox';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
  },
  dropzone: {
    textAlign: 'center',
    backgroundImage: `url(${Stripe})`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleOne: {
    textAlign: 'right',
  },
  titleTwo: {
    flexGrow: 1,
    textAlign: 'left',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});
const initialState = {
  header_tag: '',
  message: '',
  energy: 0,
  xp: 0,
  reward: [],
  deleteGift: false,
  map_name: '',
};
class AddPlayerGift extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header_tag: '',
      message: '',
      energy: 0,
      xp: 0,
      reward: [],
      deleteGift: false,
      map_name: '',
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    let s = state;
    if (props.selectedGift != null) {
      s.deleteGift = true;
      s.header_tag = props.selectedGift.localization_tag;
      s.message = props.selectedGift.message;
      s.xp = props.selectedGift.reward.xp ?? 0;
      s.map_name = props.selectedGift.map_name;
      s.energy = props.selectedGift.reward.energy ?? 0;
      s.reward = [];
      let c = 0;
      if (props.selectedGift.reward.materials) {
        s.reward = props.selectedGift.reward.materials.map((e) => {
          let res = {
            num: c,
            mat: e.material,
            count: e.count,
          };
          c++;
          return res;
        });
      }
    } else {
      s.deleteGift = false;
    }

    return s;
  };

  handleClose = () => {
    this.setState(initialState);
    this.props.closeAddGift();
  };

  handleSubmit = () => {
    let reward = {
      materials: [],
      energy: parseInt(this.state.energy),
      xp: parseInt(this.state.xp),
    };
    const s = this.state;
    console.log(s);
    for (let i = 0; i < s.reward.length; i++) {
      const c = parseInt(s.reward[i].count);
      if (s.reward[i].mat !== undefined && c !== 0) {
        reward.materials.push({ material: s.reward[i].mat, count: c });
      }
    }
    if (reward.materials.length === 0 && reward.xp === 0 && reward.energy === 0) {
      alert('At least one material must be added or xp or energy!');
      return;
    }
    this.props.closeAddGift({
      localization_tag: this.state.header_tag,
      reward,
      message: this.state.message,
      map_name: this.state.map_name,
    });
    this.setState(initialState);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  };

  handleSetMaterial = (num, _, v) => {
    let val = '';
    if (v && 'id' in v) val = v.id;
    let s = this.state;
    for (let i = 0; i < s.reward.length; i++) {
      if (s.reward[i].num === num) {
        s.reward[i].mat = val;
      }
    }
    this.setState(s);
  };

  handleSetMaterialCount = (event) => {
    const target = event.target;
    const name = target.name;
    const index = parseInt(name.replace('mc-', ''));
    let s = this.state;
    for (let i = 0; i < s.reward.length; i++) {
      if (s.reward[i].num === index) {
        s.reward[i].count = target.value;
      }
    }
    this.setState(s);
    console.log(target);
  };

  handleAddMat = () => {
    this.setState({
      ...this.state,
      reward: [...this.state.reward, { num: this.state.reward.length, count: 0, mat: 'hard' }],
    });
  };
  handleDelete = () => {
    this.setState(initialState);
    this.props.closeDeleteGift(this.props.selectedGift);
  };
  handleMapSelected = (e, v) => {
    if (v && v.name) {
      this.setState({ ...this.state, map_name: v.name });
    } else {
      this.setState({ ...this.state, map_name: '' });
    }
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="simple-dialog-title">Gift</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Header Tag"
            type="text"
            name="header_tag"
            aria-readonly={this.state.deleteGift}
            value={this.state.header_tag}
            onChange={this.handleInputChange}
            helperText="Header above popup, taken from localization. Disabled by default"
            fullWidth
          />
          <TextField
            aria-readonly={this.state.deleteGift}
            margin="dense"
            label="Message Tag"
            type="text"
            name="message"
            value={this.state.message}
            onChange={this.handleInputChange}
            helperText="Taken from localization. Disabled by default"
            fullWidth
          />
          <TextField
            margin="dense"
            label="Energy"
            name="energy"
            type="number"
            value={this.state.energy}
            aria-readonly={this.state.deleteGift}
            onChange={this.handleInputChange}
            helperText="adds energy to the player"
            fullWidth
          />
          <TextField
            margin="dense"
            label="Xp"
            name="xp"
            type="number"
            value={this.state.xp}
            aria-readonly={this.state.deleteGift}
            onChange={this.handleInputChange}
            helperText="adds xp to the player"
            fullWidth
          />
          <Autocomplete
            options={this.props.briefs}
            onChange={this.handleMapSelected}
            name="map_name"
            value={{ name: this.state.map_name }}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.name === value.name}
            renderInput={(params) => <TextField {...params} id="outlined-basic" label="Gift Map" />}
            style={{ width: 300 }}
          />
          <List component="nav" aria-label="main mailbox folders">
            {this.state.reward.map((matinfo) => (
              <ListItem key={matinfo.num}>
                <Autocomplete
                  options={this.props.materials}
                  getOptionLabel={(option) =>
                    typeof option === 'string' || option instanceof String ? option : option.id
                  }
                  onChange={(e, v) => this.handleSetMaterial(matinfo.num, e, v)}
                  getOptionSelected={(option, value) => option.id === value}
                  name={'mn-' + matinfo.num}
                  value={matinfo.mat}
                  style={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} id="outlined-basic" label="Add Material" />
                  )}
                />
                <ListItemSecondaryAction>
                  <TextField
                    type="number"
                    name={'mc-' + matinfo.num}
                    value={matinfo.count}
                    onChange={this.handleSetMaterialCount}
                    label="Count"
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            {!this.state.deleteGift && (
              <ListItem button onClick={this.handleAddMat}>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText primary="Add Material" />
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="primary">
            Cancel
          </Button>
          {!this.state.deleteGift ? (
            <Button onClick={this.handleSubmit} color="primary">
              Submit
            </Button>
          ) : (
            <Button onClick={this.handleDelete} color="secondary" startIcon={<DeleteForeverIcon />}>
              Delete
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default connect(null, { fetchPlayerGifts, setPlayerGifts })(
  withStyles(styles)(AddPlayerGift),
);
