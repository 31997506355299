import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  selectPlayer,
  fetchGameDesign,
  resetMap,
  setMap,
  addXp,
  addUnlocks,
  resetPlayer,
  addMaterial,
  enableDebug,
  fetchPlayerGifts,
  fetchPlayerOfferHistory,
  setPlayerOfferHistory,
  updatePlayerOfferHistory,
  setPlayerLtlState,
  fetchPlayerLtlState,
  updatePlayerLtlState,
  setPlayerDevices,
  fetchPlayerDevices,
} from '../redux/playerActions';
import { searchRecords } from '../redux/purchaseRecord';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Stripe from '../stripe.png';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { Icon } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InboxIcon from '@material-ui/icons/Inbox';
import {
  CloudDownloadOutlined,
  DevicesOther,
  ExpandLess,
  ExpandMore,
  Explore,
  History,
} from '@material-ui/icons';
import Collapse from '@material-ui/core/Collapse';
import PlayerGifts from './PlayerGifts';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import SettingsIcon from '@material-ui/icons/Settings';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { fetchDump } from '../redux/deviceActions';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import JsonEditDialog from './JsonEditDialog';
const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 460,
    backgroundColor: theme.palette.background.paper,
    margin: 'auto',
  },
  dropzone: {
    textAlign: 'center',
    backgroundImage: `url(${Stripe})`,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  titleOne: {
    textAlign: 'right',
  },
  titleTwo: {
    flexGrow: 1,
    textAlign: 'left',
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
});
const snakeToCamel = (str) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

// const PlayerInfo = () => {
//   const [mapName, setMapName] = useState(null);
//   const [unlocks, setUnlocks] = useState(null);
//   const [material, setMaterial] = useState(null);
//   const [materialCount, setMaterialCount] = useState(null);
//   const [xpCount, setXpCount] = useState(null);
//   const [inventoryOpen, setInventoryOpen] = useState(null);
//   const [reset, setReset] = useState(null);
//   const [resetMapName, setResetMapName] = useState(null);
//   const dispatch = useDispatch();
//   const selected = useSelector((state) => state.selected);
//   const gameDesign = useSelector((state) => state.gameDesign);
//   const { classes } = useSelector((state) => state.player);
//   const handleMapSelected = (e, v) => {
//     setMapName(v);
//   };
//   const handleResetMapSelected = (e, v) => {
//     setResetMapName(v);
//   };
//   const toggleInventory = () => {
//     setInventoryOpen(!this.state.inventoryOpen);
//   };
//   const handleBack = () => {
//     dispatch(selectPlayer(null));
//   };
//   const handleSetMap = () => {
//     dispatch(setMap(selected.user_id, mapName.name));
//   };
//   const handleResetMap = () => {
//     let ok = window.confirm('You are going to reset user progress:' + resetMapName.name);
//     if (ok) {
//       dispatch(resetMap(selected.user_id, resetMapName.name));
//     }
//   };
//   const handleSetUnlocks = (e) => {
//     setUnlocks(e.target.value);
//   };
//   const handleAddUnlocks = () => {
//     dispatch(addUnlocks(selected.user_id, unlocks));
//   };
//   const handleSetMaterial = (e, v) => {
//     setMaterial(v);
//   };
//   const handleSetMaterialCount = (e) => {
//     setMaterialCount(parseInt(e.target.value));
//   };
//   const handleSetXpCount = (e) => {
//     setXpCount(parseInt(e.target.value));
//   };
//   const handleAddMaterial = () => {
//     dispatch(addMaterial(selected.user_id, material.id, materialCount));
//     setMaterialCount(0);
//   };
//   const handleAddXp = () => {
//     dispatch(addXp(selected.user_id, xpCount));
//     setXpCount(0);
//   };
//   const componentDidMount = () => {
//     if (gameDesign == null) {
//       dispatch(fetchGameDesign());
//     }
//   };
//   const handleShowGifts = () => {
//     dispatch(fetchPlayerGifts(selected.user_id));
//   };
//   const handleMakeDumpJson = () => {
//     dispatch(fetchDump('json', null, selected.user_id));
//   };
//   const handleMakeDumpProt = () => {
//     dispatch(fetchDump('prot', null, selected.user_id));
//   };
//   const fetchIaps = () => {
//     dispatch(searchRecords(selected.user_id));
//   };
//   const handleReset = () => {
//     if (reset !== 'Reset') return;
//     dispatch(resetPlayer(selected.user_id));
//     setReset('');
//   };
//   const handleEnableDebug = () => {
//     dispatch(enableDebug(selected.user_id));
//     setReset('');
//   };
//   const handleResetInput = (e) => {
//     setReset(e.target.value);
//   };
//   const printUnlocks = () => {
//     if (!selected.unlocks) return 'Empty!';
//     return selected.unlocks.join(',');
//   };
//   const handlePurchaseHistory = () => {
//     dispatch(setPlayerOfferHistory(null));
//     dispatch(fetchPlayerOfferHistory(selected.user_id));
//   };
//   const handleSavePurchaseHistory = (newJson) => {
//     dispatch(updatePlayerOfferHistory(selected.user_id, newJson));
//   };
//   const handleClosePurchaseHistory = () => {
//     dispatch(setPlayerOfferHistory(null));
//   };
//   const handleLtlState = () => {
//     dispatch(setPlayerLtlState(null));
//     dispatch(fetchPlayerLtlState(selected.user_id));
//   };
//   const handleSaveLtlState = (newJson) => {
//     dispatch(updatePlayerLtlState(selected.user_id, newJson));
//   };
//   const handleCloseLtlState = () => {
//     dispatch(setPlayerLtlState(null));
//   };
//   const handleOpenDevices = () => {
//     dispatch(setPlayerDevices(null));
//     dispatch(fetchPlayerDevices(selected.user_id));
//   };
//   const handleCloseDevices = () => {
//     dispatch(setPlayerDevices(null));
//   };
//   const handleSaveDevices = () => {
//     alert("Can't modify this!");
//   };
//   if (selected?.gifts != null) {
//     return <PlayerGifts />;
//   }

//   let briefs = [];
//   if (gameDesign != null) {
//     briefs = gameDesign.briefs;
//   }
//   let materials = [];
//   if (gameDesign != null) {
//     materials = gameDesign.material_collection.materials;
//   }
//   let playerMaterials = [];
//   if (
//     selected?.inventory !== undefined &&
//     selected?.inventory?.materials !== undefined &&
//     selected?.inventory?.materials != null
//   ) {
//     playerMaterials = selected.inventory.materials.filter(
//       (e) => e.count !== undefined && e.count > 0,
//     );
//   }
//   return (
//     <>
//       <div>
//         {selected.offerHistory && (
//           <JsonEditDialog
//             toEdit={selected.offerHistory}
//             userId={selected.offerHistory.user_id}
//             onRefresh={handlePurchaseHistory}
//             onSave={handleSavePurchaseHistory}
//             onClose={handleClosePurchaseHistory}
//           />
//         )}
//         {selected.ltlState && (
//           <JsonEditDialog
//             toEdit={selected.ltlState}
//             userId={selected.ltlState.user_id}
//             onRefresh={handleLtlState}
//             onSave={handleSaveLtlState}
//             onClose={handleCloseLtlState}
//           />
//         )}
//         {selected.devices && (
//           <JsonEditDialog
//             toEdit={selected.devices}
//             userId={selected.user_id}
//             onRefresh={handleOpenDevices}
//             onSave={handleSaveDevices}
//             onClose={handleCloseDevices}
//           />
//         )}
//         <AppBar position="sticky">
//           <Toolbar>
//             <IconButton aria-label="back" color="inherit" onClick={handleBack}>
//               <ArrowBackIosIcon />
//             </IconButton>
//             <Typography variant="h6" className={classes.titleTwo}>
//               {selected.user_id}
//             </Typography>
//           </Toolbar>
//         </AppBar>
//         <div className={classes.root}>
//           <List component="nav" aria-label="main mailbox folders">
//             <ListItem>
//               <ListItemText
//                 primary={(selected.name ?? 'Player') + ' (' + selected.claimed_level + ')'}
//                 secondary={selected.user_id}
//               />
//             </ListItem>
//             <Divider />
//             <ListItem button onClick={handleShowGifts}>
//               <ListItemIcon>
//                 <CardGiftcardIcon />
//               </ListItemIcon>
//               <ListItemText primary="Gifts" />
//               <ListItemSecondaryAction>
//                 <KeyboardArrowRightIcon />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem button onClick={handleEnableDebug}>
//               <ListItemIcon>
//                 {selected.enable_test_mode && <SettingsIcon color="primary" />}
//                 {!selected.enable_test_mode && <SettingsIcon />}
//               </ListItemIcon>
//               <ListItemText
//                 primary={selected.enable_test_mode ? 'Test Mode is ON' : 'Test Mode is Off'}
//               />
//               <ListItemSecondaryAction>
//                 <KeyboardArrowRightIcon />
//               </ListItemSecondaryAction>
//             </ListItem>
//             {/* <Link to={'/purchases'}> */}
//             <ListItem button onClick={fetchIaps}>
//               <ListItemIcon>
//                 <ShoppingCartIcon />
//               </ListItemIcon>
//               <ListItemText primary="Find Purchases" />
//               <ListItemSecondaryAction>
//                 <KeyboardArrowRightIcon />
//               </ListItemSecondaryAction>
//             </ListItem>
//             {/* </Link> */}
//             <ListItem button onClick={handlePurchaseHistory}>
//               <ListItemIcon>
//                 <History />
//               </ListItemIcon>
//               <ListItemText primary="Offer History" />
//               <ListItemSecondaryAction>
//                 <KeyboardArrowRightIcon />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem button onClick={handleLtlState}>
//               <ListItemIcon>
//                 <Explore />
//               </ListItemIcon>
//               <ListItemText primary="Time Limited Locations State" />
//               <ListItemSecondaryAction>
//                 <KeyboardArrowRightIcon />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem button onClick={handleOpenDevices}>
//               <ListItemIcon>
//                 <DevicesOther />
//               </ListItemIcon>
//               <ListItemText primary="Player Linked Devices" />
//               <ListItemSecondaryAction>
//                 <KeyboardArrowRightIcon />
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem button onClick={handleMakeDumpProt}>
//               <ListItemIcon>
//                 <CloudDownloadIcon color="primary" />
//               </ListItemIcon>
//               <ListItemText primary="Download Dump File (PROT)" />
//             </ListItem>
//             <ListItem button onClick={handleMakeDumpJson}>
//               <ListItemIcon>
//                 <CloudDownloadOutlined />
//               </ListItemIcon>
//               <ListItemText primary="Download Dump File (JSON)" />
//             </ListItem>

//             <Divider />
//             {Object.entries(selected)
//               .filter(([_k, v]) => v !== Object(v) && v != null)
//               .map(([key, value]) => (
//                 <ListItem key={key}>
//                   <ListItemText primary={snakeToCamel(key)} secondary={value} />
//                 </ListItem>
//               ))}
//             <Divider />
//             <ListItem>
//               <Autocomplete
//                 options={briefs}
//                 onChange={handleMapSelected}
//                 getOptionLabel={(option) => option.name}
//                 //fullWidth
//                 style={{ width: 300 }}
//                 renderInput={(params) => (
//                   <TextField {...params} id="outlined-basic" label="Go To Map Name" />
//                 )}
//               />

//               <ListItemSecondaryAction>
//                 <IconButton edge="end" aria-label="comments" onClick={handleSetMap}>
//                   <Icon>send</Icon>
//                 </IconButton>
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <TextField type="number" value={xpCount} onChange={handleSetXpCount} label="Add Xp" />

//               <ListItemSecondaryAction>
//                 <IconButton edge="end" aria-label="comments" onClick={handleAddXp}>
//                   <Icon>send</Icon>
//                 </IconButton>
//               </ListItemSecondaryAction>
//             </ListItem>
//             <Divider />
//             <ListItem button onClick={toggleInventory}>
//               <ListItemIcon>
//                 <InboxIcon />
//               </ListItemIcon>
//               <ListItemText primary="Inventory" />
//               {inventoryOpen ? <ExpandLess /> : <ExpandMore />}
//             </ListItem>
//             <Collapse in={inventoryOpen} timeout="auto" unmountOnExit>
//               <List component="div" disablePadding>
//                 {playerMaterials.map((item) => (
//                   <ListItem key={item.material} className={classes.nested}>
//                     <ListItemText primary={item.material} secondary={item.count} />
//                   </ListItem>
//                 ))}
//               </List>
//             </Collapse>

//             <ListItem>
//               <Autocomplete
//                 options={materials}
//                 getOptionLabel={(option) => option.id}
//                 onChange={handleSetMaterial}
//                 //fullWidth
//                 style={{ width: 300 }}
//                 renderInput={(params) => (
//                   <TextField {...params} id="outlined-basic" label="Add Material" />
//                 )}
//               />
//             </ListItem>
//             <ListItem>
//               <TextField
//                 type="number"
//                 value={materialCount}
//                 onChange={handleSetMaterialCount}
//                 label="Count"
//               />

//               <ListItemSecondaryAction>
//                 <IconButton edge="end" aria-label="comments" onClick={handleAddMaterial}>
//                   <Icon>send</Icon>
//                 </IconButton>
//               </ListItemSecondaryAction>
//             </ListItem>

//             <Divider />
//             <ListItem>
//               <Autocomplete
//                 options={briefs}
//                 onChange={handleResetMapSelected}
//                 getOptionLabel={(option) => option.name}
//                 //fullWidth
//                 style={{ width: 300 }}
//                 renderInput={(params) => (
//                   <TextField {...params} id="outlined-basic" label="Reset User Map" />
//                 )}
//               />

//               <ListItemSecondaryAction>
//                 <IconButton edge="end" aria-label="comments" onClick={handleResetMap}>
//                   <Icon>send</Icon>
//                 </IconButton>
//               </ListItemSecondaryAction>
//             </ListItem>
//             <ListItem>
//               <TextField type="text" value={reset} onChange={handleResetInput} label="Reset" />

//               <ListItemSecondaryAction>
//                 <IconButton edge="end" aria-label="comments" onClick={handleReset}>
//                   <Icon>send</Icon>
//                 </IconButton>
//               </ListItemSecondaryAction>
//             </ListItem>
//           </List>
//         </div>
//       </div>
//     </>
//   );
// };
// export default PlayerInfo;
class PlayerInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mapName: {},
      unlocks: '',
      material: {},
      materialCount: 0,
      xpCount: 0,
      inventoryOpen: false,
      reset: '',
      resetMapName: {},
    };
  }

  handleMapSelected = (e, v) => {
    this.setState({ ...this.state, mapName: v });
  };
  handleResetMapSelected = (e, v) => {
    this.setState({ ...this.state, resetMapName: v });
  };
  toggleInventory = () => {
    this.setState({ ...this.state, inventoryOpen: !this.state.inventoryOpen });
  };
  handleBack = () => {
    this.props.selectPlayer(null);
  };
  handleSetMap = () => {
    this.props.setMap(this.props.selected.user_id, this.state.mapName.name);
  };
  handleResetMap = () => {
    let ok = window.confirm('You are going to reset user progress:' + this.state.resetMapName.name);
    if (ok) {
      this.props.resetMap(this.props.selected.user_id, this.state.resetMapName.name);
    }
  };
  handleSetUnlocks = (e) => {
    this.setState({ ...this.state, unlocks: e.target.value });
  };
  handleAddUnlocks = () => {
    this.props.addUnlocks(this.props.selected.user_id, this.state.unlocks);
  };
  handleSetMaterial = (e, v) => {
    this.setState({ ...this.state, material: v });
  };
  handleSetMaterialCount = (e) => {
    this.setState({ ...this.state, materialCount: parseInt(e.target.value) });
  };
  handleSetXpCount = (e) => {
    this.setState({ ...this.state, xpCount: parseInt(e.target.value) });
  };
  handleAddMaterial = () => {
    this.props.addMaterial(
      this.props.selected.user_id,
      this.state.material.id,
      this.state.materialCount,
    );
    this.setState({ ...this.state, materialCount: 0 });
  };
  handleAddXp = () => {
    this.props.addXp(this.props.selected.user_id, this.state.xpCount);
    this.setState({ ...this.state, xpCount: 0 });
  };
  componentDidMount = () => {
    if (this.props.gameDesign == null) {
      this.props.fetchGameDesign();
    }
  };
  handleShowGifts = () => {
    this.props.fetchPlayerGifts(this.props.selected.user_id);
  };
  handleMakeDumpJson = () => {
    this.props.fetchDump('json', null, this.props.selected.user_id);
  };
  handleMakeDumpProt = () => {
    this.props.fetchDump('prot', null, this.props.selected.user_id);
  };
  fetchIaps = () => {
    this.props.searchRecords(this.props.selected.user_id);
    console.log(this.props.selected.user_id);
  };
  handleReset = () => {
    if (this.state.reset !== 'Reset') return;
    this.props.resetPlayer(this.props.selected.user_id);
    this.setState({ ...this.state, reset: '' });
  };
  handleEnableDebug = () => {
    this.props.enableDebug(this.props.selected.user_id);
    this.setState({ ...this.state, reset: '' });
  };
  handleResetInput = (e) => {
    this.setState({ ...this.state, reset: e.target.value });
  };
  printUnlocks = () => {
    if (!this.props.selected.unlocks) return 'Empty!';
    return this.props.selected.unlocks.join(',');
  };
  handlePurchaseHistory = () => {
    this.props.setPlayerOfferHistory(null);
    this.props.fetchPlayerOfferHistory(this.props.selected.user_id);
  };
  handleSavePurchaseHistory = (newJson) => {
    this.props.updatePlayerOfferHistory(this.props.selected.user_id, newJson);
  };
  handleClosePurchaseHistory = () => {
    this.props.setPlayerOfferHistory(null);
  };
  handleLtlState = () => {
    this.props.setPlayerLtlState(null);
    this.props.fetchPlayerLtlState(this.props.selected.user_id);
  };
  handleSaveLtlState = (newJson) => {
    this.props.updatePlayerLtlState(this.props.selected.user_id, newJson);
  };
  handleCloseLtlState = () => {
    this.props.setPlayerLtlState(null);
  };
  handleOpenDevices = () => {
    this.props.setPlayerDevices(null);
    this.props.fetchPlayerDevices(this.props.selected.user_id);
  };
  handleCloseDevices = () => {
    this.props.setPlayerDevices(null);
  };
  handleSaveDevices = () => {
    alert("Can't modify this!");
  };
  render() {
    if (this.props.selected.gifts != null) {
      return <PlayerGifts />;
    }
    const { classes } = this.props;
    let briefs = [];
    if (this.props.gameDesign != null) {
      briefs = this.props.gameDesign.briefs;
    }
    let materials = [];
    if (this.props.gameDesign != null) {
      materials = this.props.gameDesign.material_collection.materials;
    }
    let playerMaterials = [];
    if (
      this.props.selected.inventory !== undefined &&
      this.props.selected.inventory.materials !== undefined &&
      this.props.selected.inventory.materials != null
    ) {
      playerMaterials = this.props.selected.inventory.materials.filter(
        (e) => e.count !== undefined && e.count > 0,
      );
    }

    return (
      <div>
        {this.props.selected.offerHistory && (
          <JsonEditDialog
            toEdit={this.props.selected.offerHistory}
            userId={this.props.selected.offerHistory.user_id}
            onRefresh={this.handlePurchaseHistory}
            onSave={this.handleSavePurchaseHistory}
            onClose={this.handleClosePurchaseHistory}
          />
        )}
        {this.props.selected.ltlState && (
          <JsonEditDialog
            toEdit={this.props.selected.ltlState}
            userId={this.props.selected.ltlState.user_id}
            onRefresh={this.handleLtlState}
            onSave={this.handleSaveLtlState}
            onClose={this.handleCloseLtlState}
          />
        )}
        {this.props.selected.devices && (
          <JsonEditDialog
            toEdit={this.props.selected.devices}
            userId={this.props.selected.user_id}
            onRefresh={this.handleOpenDevices}
            onSave={this.handleSaveDevices}
            onClose={this.handleCloseDevices}
          />
        )}
        <AppBar position="sticky">
          <Toolbar>
            <IconButton aria-label="back" color="inherit" onClick={this.handleBack}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h6" className={classes.titleTwo}>
              {this.props.selected.user_id}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.root}>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem>
              <ListItemText
                primary={
                  (this.props.selected.name ?? 'Player') +
                  ' (' +
                  this.props.selected.claimed_level +
                  ')'
                }
                secondary={this.props.selected.user_id}
              />
            </ListItem>
            <Divider />
            <ListItem button onClick={this.handleShowGifts}>
              <ListItemIcon>
                <CardGiftcardIcon />
              </ListItemIcon>
              <ListItemText primary="Gifts" />
              <ListItemSecondaryAction>
                <KeyboardArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button onClick={this.handleEnableDebug}>
              <ListItemIcon>
                {this.props.selected.enable_test_mode && <SettingsIcon color="primary" />}
                {!this.props.selected.enable_test_mode && <SettingsIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  this.props.selected.enable_test_mode ? 'Test Mode is ON' : 'Test Mode is Off'
                }
              />
              <ListItemSecondaryAction>
                <KeyboardArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <Link to={'/purchases'}>
              <ListItem button onClick={this.fetchIaps}>
                <ListItemIcon>
                  <ShoppingCartIcon />
                </ListItemIcon>
                <ListItemText primary="Find Purchases" />
                <ListItemSecondaryAction>
                  <KeyboardArrowRightIcon />
                </ListItemSecondaryAction>
              </ListItem>
            </Link>
            <ListItem button onClick={this.handlePurchaseHistory}>
              <ListItemIcon>
                <History />
              </ListItemIcon>
              <ListItemText primary="Offer History" />
              <ListItemSecondaryAction>
                <KeyboardArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button onClick={this.handleLtlState}>
              <ListItemIcon>
                <Explore />
              </ListItemIcon>
              <ListItemText primary="Time Limited Locations State" />
              <ListItemSecondaryAction>
                <KeyboardArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button onClick={this.handleOpenDevices}>
              <ListItemIcon>
                <DevicesOther />
              </ListItemIcon>
              <ListItemText primary="Player Linked Devices" />
              <ListItemSecondaryAction>
                <KeyboardArrowRightIcon />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem button onClick={this.handleMakeDumpProt}>
              <ListItemIcon>
                <CloudDownloadIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="Download Dump File (PROT)" />
            </ListItem>
            <ListItem button onClick={this.handleMakeDumpJson}>
              <ListItemIcon>
                <CloudDownloadOutlined />
              </ListItemIcon>
              <ListItemText primary="Download Dump File (JSON)" />
            </ListItem>

            <Divider />
            {Object.entries(this.props.selected)
              .filter(([_k, v]) => v !== Object(v) && v != null)
              .map(([key, value]) => (
                <ListItem key={key}>
                  <ListItemText primary={snakeToCamel(key)} secondary={value} />
                </ListItem>
              ))}
            <Divider />
            <ListItem>
              <Autocomplete
                options={briefs}
                onChange={this.handleMapSelected}
                getOptionLabel={(option) => option.name}
                //fullWidth
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} id="outlined-basic" label="Go To Map Name" />
                )}
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments" onClick={this.handleSetMap}>
                  <Icon>send</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <TextField
                type="number"
                value={this.state.xpCount}
                onChange={this.handleSetXpCount}
                label="Add Xp"
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments" onClick={this.handleAddXp}>
                  <Icon>send</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
            <ListItem button onClick={this.toggleInventory}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="Inventory" />
              {this.state.inventoryOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={this.state.inventoryOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {playerMaterials.map((item) => (
                  <ListItem key={item.material} className={classes.nested}>
                    <ListItemText primary={item.material} secondary={item.count} />
                  </ListItem>
                ))}
              </List>
            </Collapse>

            <ListItem>
              <Autocomplete
                options={materials}
                getOptionLabel={(option) => option.id}
                onChange={this.handleSetMaterial}
                //fullWidth
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} id="outlined-basic" label="Add Material" />
                )}
              />
            </ListItem>
            <ListItem>
              <TextField
                type="number"
                value={this.state.materialCount}
                onChange={this.handleSetMaterialCount}
                label="Count"
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments" onClick={this.handleAddMaterial}>
                  <Icon>send</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>

            <Divider />
            <ListItem>
              <Autocomplete
                options={briefs}
                onChange={this.handleResetMapSelected}
                getOptionLabel={(option) => option.name}
                //fullWidth
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} id="outlined-basic" label="Reset User Map" />
                )}
              />

              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments" onClick={this.handleResetMap}>
                  <Icon>send</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <TextField
                type="text"
                value={this.state.reset}
                onChange={this.handleResetInput}
                label="Reset"
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="comments" onClick={this.handleReset}>
                  <Icon>send</Icon>
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { ...state.player };
};

export default connect(mapStateToProps, {
  selectPlayer,
  enableDebug,
  fetchGameDesign,
  fetchDump,
  resetMap,
  setMap,
  addXp,
  addUnlocks,
  resetPlayer,
  addMaterial,
  fetchPlayerGifts,
  searchRecords,
  fetchPlayerOfferHistory,
  setPlayerOfferHistory,
  updatePlayerOfferHistory,
  setPlayerLtlState,
  fetchPlayerLtlState,
  updatePlayerLtlState,
  setPlayerDevices,
  fetchPlayerDevices,
})(withStyles(styles)(PlayerInfo));
